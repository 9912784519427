import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TonConnectButton } from '@tonconnect/ui-react';
import { MainTitle } from '../../components/main-title';
import { Link } from '../../components/link';
import { useTextTelegram } from '../../hooks/useTextTelegram';
import TelegramLogo from '../../assets/images/settings/telegram-logo.png';
import GithubLogo from '../../assets/images/settings/github-logo.png';
// import LogOut from '../../assets/images/settings/log-out.svg'
import s from './settings-page.module.scss';
export const SettingsPage = ({ isTestnet, isConnected, balance, isTg }) => {
    const telegramText = useTextTelegram(isTg);
    return (_jsxs("section", { className: s.settings, children: [_jsxs("div", { className: s.settingsHeader, children: [_jsx(MainTitle, { title: "Settings", isTg: isTg }), _jsx(TonConnectButton, {})] }), _jsxs("div", { className: s.settingsActions, children: [_jsx(Link, { href: "https://t.me/delab", icon: TelegramLogo, iconAlt: "Telegram Logo", text: "Telegram", isTg: isTg }), _jsx(Link, { href: "https://github.com/delab-team", icon: GithubLogo, iconAlt: "Github Logo", text: "Github", isTg: isTg })] }), _jsx("div", { className: s.settingsVersion, style: telegramText, children: "v. 1.2" }), _jsx("div", { className: s.settingsVersion, style: telegramText, children: isTestnet ? 'testnet' : 'mainnet' })] }));
};
