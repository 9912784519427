import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useRef } from 'react';
import QrScanner from 'qr-scanner';
import { Address } from 'ton-core';
import { useNavigate } from 'react-router-dom';
import { MainTitle } from '../../components/main-title';
import { Link } from '../../components/link';
import { ROUTES } from '../../utils/router';
import { useMediaQuery } from '../../hooks/use-media-query';
import { useTextTelegram } from '../../hooks/useTextTelegram';
import { useBgTelegram } from '../../hooks/useBgTelegram';
import { useBg2Telegram } from '../../hooks/useBg2Telegram';
import QR from '../../assets/images/qr/qr.svg';
import s from './qr-scanner-page.module.scss';
export const QrScannerPage = ({ address, setAddress, isTg }) => {
    const videoRef = useRef(null);
    const [qrResult, setQRResult] = useState('');
    // const [ address, setAddress ] = useState<string>('')
    const telegramText = useTextTelegram(isTg);
    const telegramBG = useBgTelegram(isTg);
    const isMobile = useMediaQuery(768);
    const navigate = useNavigate();
    useEffect(() => {
        const initializeScanner = async () => {
            const videoElement = videoRef.current;
            if (!videoElement) {
                console.error('Video element not found');
                return;
            }
            try {
                const hasCamera = await QrScanner.hasCamera();
                if (!hasCamera) {
                    console.error('No camera found');
                    return;
                }
                const scanner = new QrScanner(videoElement, (result) => {
                    setQRResult(result);
                });
                scanner.start();
                // eslint-disable-next-line consistent-return
                return () => {
                    scanner.stop();
                };
            }
            catch (error) {
                console.error('Error initializing QR scanner:', error);
            }
        };
        initializeScanner();
    }, []);
    useEffect(() => {
        const scanQRCodeFromImage = async () => {
            try {
                const result = await QrScanner.scanImage(QR);
                if (result) {
                    const string = result.split('=');
                    const string2 = string[string.length - 1];
                    try {
                        const addr = Address.parse(string2);
                        setAddress(addr.toString());
                    }
                    catch (error) {
                        console.log(error);
                    }
                    setQRResult(result);
                }
                else {
                    console.error('QR code not found in the image');
                }
            }
            catch (error) {
                console.error('Error scanning QR code from image:', error);
            }
        };
        scanQRCodeFromImage();
    }, []);
    useEffect(() => {
        if (address) {
            try {
                const addr = Address.parse(address);
                navigate(ROUTES.ACTIVATE);
            }
            catch (err) {
                console.error(err);
            }
        }
    }, [address]);
    const telegramBG2 = useBg2Telegram(isTg);
    const inputStyles = { ...telegramBG2, ...telegramText };
    return (_jsxs("section", { children: [_jsx(MainTitle, { title: "Scan QR code", isTg: isTg }), _jsx("div", { className: s.qrContent, children: _jsxs("div", { className: s.qr, children: [_jsx("video", { className: s.qrCamera, ref: videoRef, autoPlay: true, playsInline: true }), _jsx("img", { src: QR, alt: "qr img", className: s.qrImage })] }) }), qrResult.length > 1 && _jsx(Link, { text: qrResult, href: qrResult, isTg: isTg }), _jsxs("div", { className: s.formBlock, children: [_jsx("label", { className: s.formLabel, style: telegramText, children: "Address coupon" }), _jsx("input", { type: "text", value: address, onChange: e => setAddress(e.target.value), className: s.formInput, style: inputStyles })] })] }));
};
