import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '../../hooks/use-media-query';
import { useBg2Telegram } from '../../hooks/useBg2Telegram';
import { useTextTelegram } from '../../hooks/useTextTelegram';
import { SvgSelector } from '../../assets/icons/svg-selector';
import { ROUTES } from '../../utils/router';
import s from './main-actions.module.scss';
const mobileMenu = [
    {
        id: 14124,
        path: ROUTES.YOUR_CHECKS,
        icon: 'checks'
    },
    {
        id: 15677,
        path: ROUTES.QR_SCANNER,
        icon: 'scanner'
    },
    {
        id: 12345,
        path: ROUTES.SETTINGS,
        icon: 'settings'
    }
];
const pcMenu = [
    {
        id: 12671,
        path: ROUTES.YOUR_CHECKS,
        icon: 'checks',
        content: 'Checks'
    },
    {
        id: 17155,
        path: ROUTES.QR_SCANNER,
        icon: 'scanner',
        content: 'Scanner'
    },
    {
        id: 17147,
        path: ROUTES.SETTINGS,
        icon: 'settings',
        content: 'Settings'
    }
];
export const MainActions = ({ isTg }) => {
    const isMobile = useMediaQuery(768);
    const path = window.location.pathname;
    const telegramBG = useBg2Telegram(isTg);
    const telegramText = useTextTelegram(isTg);
    const [activeLink, setActiveLink] = useState(path);
    const [style, setStyle] = useState({});
    useEffect(() => {
        if (isTg) {
            setStyle({
                backgroundColor: 'var(--tg-theme-button-color)',
                color: 'var(--tg-theme-button-text-color)',
                important: 'true'
            });
        }
        else {
            setStyle({});
        }
    }, [isTg]);
    const tgStylePc = isTg ? { ...telegramText, color: '#fff' } : {};
    return (_jsx(_Fragment, { children: isMobile ? (_jsx("nav", { className: s.menuMobile, style: style, children: mobileMenu.map(el => (_jsx(Link, { to: el.path, style: telegramText, children: _jsx("button", { className: s.menuMobileButton, children: _jsx(SvgSelector, { id: el.icon, isTg: isTg }) }) }, `mobile-el-${el.id}`))) })) : (_jsx("nav", { className: s.menuPc, style: style, children: pcMenu.map(el => (_jsx(Link, { to: el.path, children: _jsxs("button", { className: `${s.menuPcButton} ${activeLink === el.path && !isTg ? s.activeLink : ''}`, onClick: () => setActiveLink(el.path), children: [_jsx(SvgSelector, { id: el.icon, isTg: isTg }), _jsx("span", { style: tgStylePc, children: el.content })] }) }, `pc-el-${el.id}`))) })) }));
};
