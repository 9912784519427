import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import CHECK_IMG from '../../assets/images/checks/checklist.svg';
import TokenPriceHook from '../../hooks/token-price-hook';
import { Coupon } from '../../logic/coupon';
import { fixAmount } from '../../utils/fix-amount';
import { SvgSelector } from '../../assets/icons/svg-selector';
import s from './check-card.module.scss';
export const CheckCard = ({ el, index, handleCheckCardClick, isTestnet, isTg }) => {
    const [bal, setBal] = useState('0');
    const [inter, setInter] = useState(undefined);
    const [style, setStyle] = useState({});
    useEffect(() => {
        if (isTg) {
            setStyle({
                backgroundColor: 'var(--tg-theme-link-color)',
                important: 'true'
            });
        }
        else {
            setStyle({});
        }
    }, [isTg]);
    useEffect(() => {
        const fetchBalance = async () => {
            try {
                const bl = await Coupon.getSumCoupon(el.address, isTestnet);
                setBal(bl);
            }
            catch (error) {
                console.error(error);
                setBal('0');
            }
        };
        if (inter)
            clearInterval(inter);
        const timer = setInterval(() => {
            Coupon.getSumCoupon(el.address, isTestnet).then((bl) => {
                setBal(bl);
            });
        }, 5000);
        setInter(timer);
        fetchBalance();
    }, [el.address]);
    return (_jsxs("li", { className: s.check, onClick: () => handleCheckCardClick(el?.id, el?.typeCheck), style: style, children: [_jsxs("div", { className: s.checkBody, children: [_jsx("div", { className: s.checkNotifications, children: _jsx("img", { src: CHECK_IMG, alt: "check image" }) }), _jsxs("div", { className: s.checkInfo, children: [_jsxs("p", { className: s.checkTitle, children: [el.typeCheck === 'Personal'
                                        ? 'Check'
                                        : el.typeCheck === 'Multicheck'
                                            ? 'Multicheck'
                                            : null, _jsxs("span", { className: s.checkIndex, children: ["#", index] })] }), _jsxs("p", { className: s.checkSum, style: { color: isTg ? 'white' : '' }, children: ["Sum: ", fixAmount(bal), " TON (", _jsx(TokenPriceHook, { tokenAmount: Number(fixAmount(bal)) }), ")"] })] })] }), _jsx(SvgSelector, { id: "chevron-right" })] }));
};
