import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import clsx from 'clsx';
import './button.scss';
export const Button = ({ children, variant = 'action-button', startIcon, endIcon, onClick, isTg, style, ...props }) => {
    const className = clsx('btn', { actionButton: variant === 'action-button' }, { smallButton: variant === 'small-button' }, { primaryButton: variant === 'primary-button' }, { blackButton: variant === 'black-button' }, { blackSmallButton: variant === 'blackS-button' });
    const [tgStyle, setTgStyle] = useState({});
    useEffect(() => {
        if (isTg) {
            setTgStyle({
                backgroundColor: 'var(--tg-theme-button-color)',
                color: 'var(--tg-theme-button-text-color)',
                border: 0,
                important: 'true'
            });
        }
        else {
            setTgStyle({});
        }
    }, [isTg]);
    // const telegramText: CSSProperties = useTextTelegram(isTg)
    // const styleButton = { ...button_color, ...telegramText }
    const styleBtn = { ...style, ...tgStyle };
    return (_jsxs("button", { className: className, onClick: onClick, type: "button", ...props, style: styleBtn, children: [!!startIcon && _jsx("img", { src: startIcon, alt: "" }), children, !!endIcon && _jsx("img", { src: endIcon, alt: "" })] }));
};
