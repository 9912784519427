import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable import/no-cycle */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TonConnectButton } from '@tonconnect/ui-react';
import { Button } from '../../components/ui/button';
import { CheckCard } from '../../components/check-card';
import { Check } from '../../components/check';
import { Multichecks } from '../../components/multichecks';
import { ROUTES } from '../../utils/router';
import { useMediaQuery } from '../../hooks/use-media-query';
import { useTonAddress } from '../../hooks/useTonAdress';
import { useBgTelegram } from '../../hooks/useBgTelegram';
import { useTextTelegram } from '../../hooks/useTextTelegram';
import { StorageWallet } from '../../logic/storage';
import PLUS from '../../assets/images/your-checks/plus.svg';
import s from './your-checks-page.module.scss';
export const YourChecksPage = ({ isTestnet, isTg }) => {
    const [selectedCheckCard, setSelectedCheckCard] = useState({
        id: '',
        selected: ''
    });
    const telegramBG = useBgTelegram(isTg);
    const telegramText = useTextTelegram(isTg);
    const [checks, setChecks] = useState([]);
    const storageWallet = new StorageWallet();
    const rawAddress = useTonAddress(false);
    useEffect(() => {
        const allCoupons = storageWallet.getAllCoupons();
        setChecks(allCoupons);
    }, []);
    const isMobile = useMediaQuery(768);
    const navigate = useNavigate();
    const handleCheckCardClick = (id, selected) => {
        setSelectedCheckCard({ id, selected });
    };
    const renderPopupComponent = () => {
        if (selectedCheckCard.selected === 'Multicheck') {
            return _jsx(Multichecks, { selectedCheckCard: selectedCheckCard, setSelectedCheckCard: setSelectedCheckCard, isTestnet: isTestnet, isTg: isTg });
        }
        if (selectedCheckCard.selected === 'Personal') {
            return _jsx(Check, { selectedCheckCard: selectedCheckCard, setSelectedCheckCard: setSelectedCheckCard, isTestnet: isTestnet, isTg: isTg });
        }
        return null;
    };
    return (_jsxs("section", { className: s.yourChecks, children: [_jsxs("div", { className: s.yourChecksTop, style: telegramBG, children: [_jsxs("div", { className: s.headerActions, children: [_jsx("h1", { className: s.headerTitle, style: telegramText, children: "DeCoupons" }), _jsxs("div", { className: s.headerContainer, children: [!isMobile && (_jsx(Button, { variant: "small-button", startIcon: PLUS, onClick: () => navigate(ROUTES.CREATE_CHECK), isTg: isTg })), _jsx(TonConnectButton, {})] })] }), _jsxs("div", { className: s.checkTop, children: [_jsx("h2", { className: s.subtitle, style: telegramText, children: "Your checks" }), isMobile && (_jsx("div", { className: s.actionButton, children: _jsx(Button, { variant: "small-button", startIcon: PLUS, onClick: () => navigate(ROUTES.CREATE_CHECK), isTg: isTg }) }))] })] }), _jsx("div", { className: s.yourChecksBody, children: _jsx("ul", { className: `${s.checkList} ${checks.length > 1 ? s.checkLists : ''}`, children: checks.length < 1 ? (_jsx("div", { className: s.pureCheck, style: telegramText, children: "Your check list is empty" })) : (checks.map((el, index) => (el.userAddress === rawAddress && (_jsx(CheckCard, { el: el, index: index + 1, handleCheckCardClick: handleCheckCardClick, isTestnet: isTestnet, isTg: isTg }, el.id))))) }) }), renderPopupComponent()] }));
};
